import { axiosPrivateInstance } from "../api/apiConfig";
import { useEffect } from 'react'
import useAuth from "./useAuth";
import useRefreshToken from "./useRefreshToken";

export default function useAxiosPrivate() {
    const { accessToken, setAccessToken, csrftoken, user } = useAuth()
    const refresh = useRefreshToken()

    useEffect(() => {
        const requestIntercept = axiosPrivateInstance.interceptors.request.use(
            (config) => {
                if (!config.headers["Authorization"]) {
                    config.headers['Authorization'] = `Bearer ${accessToken}`;
                    config.headers['X-CSRFToken'] = csrftoken;
                }
                
                // Check if the request data is FormData
                if (config.data instanceof FormData) {
                    // For FormData, let the browser set the Content-Type
                    delete config.headers['Content-Type'];
                } else {
                    // For other requests, set Content-Type to application/json
                    config.headers['Content-Type'] = 'application/json';
                }
                
                return config;
            },
            (error) => Promise.reject(error)
        );

        const responseIntercept = axiosPrivateInstance.interceptors.response.use(
            response => response,
            async (error) => {
                const prevRequest = error?.config;
                if ((error?.response?.status === 403 || error?.response?.status === 401) && !prevRequest?.sent) {
                    prevRequest.sent = true;
                    const { csrfToken: newCSRFToken, accessToken: newAccessToken } = await refresh();
                    setAccessToken(newAccessToken);
                    prevRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
                    prevRequest.headers['X-CSRFToken'] = newCSRFToken;
                    
                    // Preserve the original Content-Type header
                    if (prevRequest.data instanceof FormData) {
                        delete prevRequest.headers['Content-Type'];
                    } else {
                        prevRequest.headers['Content-Type'] = 'application/json';
                    }
                    
                    return axiosPrivateInstance(prevRequest);
                }
                return Promise.reject(error);
            }
        );

        return () => {
            axiosPrivateInstance.interceptors.request.eject(requestIntercept);
            axiosPrivateInstance.interceptors.response.eject(responseIntercept);
        }
    }, [accessToken, user, refresh, csrftoken, setAccessToken]);

    return axiosPrivateInstance;
}