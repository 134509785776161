import React from 'react';

function Loading(props) {
    return (
        <div className='container mt-4'>
        Loading...
        <p className="placeholder-glow">
          <span className="placeholder col-12"></span>
        </p>
        <p className="placeholder-wave">
          <span className="placeholder col-12"></span>
        </p>
      </div>
    );
}

export default Loading;