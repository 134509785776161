import React, { useState,useEffect } from 'react';
import { Search, UserMinus } from 'lucide-react'; // Importing Lucide icons
import useTenants from '../hooks/useTenants';
import { axiosPrivateInstance } from '../api/apiConfig';
import Loading from './Loading';

function RemoveTenant(props) {
    const { tenants, loading, error: tenantsError, getTenants } = useTenants();
    const [selectedTenant, setSelectedTenant] = useState(null);
    const [removalReason, setRemovalReason] = useState('');
    const [comments, setComments] = useState('');
    const [departureDate, setDepartureDate] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [removalError, setRemovalError] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const[successMsg,setSuccessMsg] = useState(null);
    useEffect(() => {
        if (!isSubmitting) {
            setSelectedTenant(null);
            setRemovalReason('');
            setComments('');
            setDepartureDate('');
        }
    }, [isSubmitting]);
    const filteredTenants = tenants.filter(tenant => 
        tenant.full_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        tenant.room.room_number.toString().includes(searchTerm)
    );
    const displayedTenants = filteredTenants.slice(0, 5);

    const handleRemoveTenant = async (event) => {
        event.preventDefault();
    
        if (!selectedTenant) return; // Ensure a tenant is selected
    
        const formData = new FormData();
        formData.append('id', selectedTenant.id);
        formData.append('removal_reason', removalReason);
        formData.append('comments', comments);
        formData.append('departure_date', departureDate);

        setIsSubmitting(true); 
        setRemovalError(null); 

        try {
            const response = await axiosPrivateInstance.post('/onboard/remove_tenant/', formData);
            if (response.status === 200) {
                setSuccessMsg('Tenant removed successfully!');
                await getTenants();
            } else {
                throw new Error('Failed to remove tenant');
            }
        } catch (error) {
            console.error('An error occurred:', error);
            setRemovalError('An error occurred while removing the tenant. Please try again.');
        } finally {
            setIsSubmitting(false); // Stop submitting
        }
    };

    if (loading) {
        return <Loading />;
    }

    return (
        <div className="bg-light rounded ">
            
            <h4 className="mb-4 d-flex align-items-center">
                <UserMinus className="me-2" size={24} />
                Remove Tenant
            </h4>
            {successMsg && <div className="alert alert-info">{successMsg}</div>}
            
            {tenantsError && <div className="alert alert-danger">{tenantsError}</div>}
            {removalError && <div className="alert alert-danger">{removalError}</div>}

            <div className="mb-2">
                <label htmlFor="searchBar" className="form-label small">Search Tenant</label>
                <div className="input-group">
                    <input 
                        type="text" 
                        id="searchBar" 
                        className="form-control" 
                        placeholder="Search by name or room number"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <span className="input-group-text">
                        <Search size={20} />
                    </span>
                </div>
            </div>

            <div className="mb-4">
                <div className="list-group">
                    {displayedTenants.map(tenant => (
                        <button 
                            key={tenant.id}
                            className={`list-group-item list-group-item-action ${
                                selectedTenant?.id === tenant.id ? 'active' : ''
                            }`}
                            onClick={() => setSelectedTenant(tenant)}
                        >
                            {tenant.full_name} | {tenant.room.room_number}
                        </button>
                    ))}
                    {filteredTenants.length > 5 && (
                        <div className="text-muted mt-2 small">
                            +{filteredTenants.length - 5} more tenants match your search
                        </div>
                    )}
                    {filteredTenants.length === 0 && (
                        <div className="text-muted mt-2">
                            No tenants found matching your search
                        </div>
                    )}
                </div>
            </div>

            <form onSubmit={handleRemoveTenant}>
                <div className="mb-3">
                    <label htmlFor="removalReason" className="form-label">Reason for Removal</label>
                    <select 
                        id="removalReason" 
                        className="form-select"
                        value={removalReason} 
                        onChange={(e) => setRemovalReason(e.target.value)}
                    >
                        <option value="">Select a reason</option>
                        <option value="disciplinary">Disciplinary</option>
                        <option value="insufficient_funds">Insufficient Funds</option>
                        <option value="transfer">Transfer</option>
                        <option value="other">Other</option>
                    </select>
                </div>

                <div className="mb-3">
                    <label htmlFor="departureDate" className="form-label">Date of Departure</label>
                    <input 
                        type="date" 
                        id="departureDate" 
                        className="form-control"
                        value={departureDate} 
                        onChange={(e) => setDepartureDate(e.target.value)}
                    />
                </div>

                <div className="mb-3">
                    <label htmlFor="comments" className="form-label">Comments</label>
                    <textarea 
                        id="comments" 
                        className="form-control"
                        rows="3" 
                        value={comments} 
                        onChange={(e) => setComments(e.target.value)}
                    />
                </div>

                <button 
                    type="submit" 
                    className={`btn btn-danger w-100`}
                    disabled={!selectedTenant || !removalReason || !departureDate || isSubmitting}
                >
                    {isSubmitting ? 'Removing...' : 'Remove Tenant'}
                </button>
            </form>
        </div>
    );
}

export default RemoveTenant;