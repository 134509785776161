import React, { useState, useEffect } from 'react';
import { Search,  MoveRight,DoorOpen,DoorClosed } from 'lucide-react';
import useTenants from '../hooks/useTenants';
import useRooms from '../hooks/useRooms';
import { axiosPrivateInstance } from '../api/apiConfig';
import Loading from './Loading';

function TransferTenant(props) {
    const { tenants, loading,   getTenants } = useTenants();
    const { rooms,  } = useRooms();
    const [selectedTenant, setSelectedTenant] = useState(null);
    const [newRoom, setNewRoom] = useState('');
    const [transferReason, setTransferReason] = useState('');
    const [transferDate, setTransferDate] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [transferError, setTransferError] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [successMsg, setSuccessMsg] = useState(null);

    useEffect(() => {
        if (!isSubmitting) {
            setSelectedTenant(null);
            setTransferReason('');
            setTransferDate('');
            setNewRoom('');
        }
    }, [isSubmitting]);

    const filteredTenants = tenants.filter(tenant => 
        tenant.full_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        tenant.room.room_number.toString().includes(searchTerm)
    );
    const displayedTenants = filteredTenants.slice(0, 5);

    const handleTransferTenant = async (event) => {
        event.preventDefault();
    
        if (!selectedTenant || !newRoom) return;

        const formData = new FormData();
        formData.append('id', selectedTenant.id);
        formData.append('previous_room', selectedTenant.room.room_number);
        formData.append('room_number', newRoom);
        formData.append('transfer_reason', transferReason);
        formData.append('transfer_date', transferDate);
        formData.append('transfer_reason', 'upgrade')
        setIsSubmitting(true);
        setTransferError(null);

        try {
            const response = await axiosPrivateInstance.post('/onboard/transfer_tenant/', formData);
        
            if (response.status === 201) {
                setSuccessMsg('Tenant transferred successfully!');
                await getTenants();
            }
             else {
                throw new Error('Failed to transfer tenant');
            }
        } catch (error) {
            console.error('An error occurred:', error);
            setTransferError('An error occurred while transferring the tenant. Please try again.');
        } finally {
            setIsSubmitting(false);
        }
    };

    if (loading) {
        return <Loading />;
    }
    return (
        <div className="bg-light rounded shadow-sm p-4">

            <h5 className="mb-4 d-flex align-items-center">
            <DoorClosed className="me-2" size={24} />

                <MoveRight className="me-2" size={24} />
            <DoorOpen className="me-2" size={24} />

                Change tenant room
            </h5>
            {successMsg && <div className="alert alert-info">{successMsg}</div>}
            
            {transferError && <div className="alert alert-danger">{transferError}</div>}

            <form onSubmit={handleTransferTenant}>
            <div className="mb-3">
                <label htmlFor="searchBar" className="form-label small">Search Tenant</label>
                <div className="input-group">
                    <input 
                        type="text" 
                        id="searchBar" 
                        className="form-control" 
                        placeholder="Search by name or room number"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <span className="input-group-text">
                        <Search size={20} />
                    </span>
                </div>
            </div>
                <div className="mb-4">
                <div className="list-group">
                    {displayedTenants.map(tenant => (
                        <button 
                            key={tenant.id}
                            className={`list-group-item list-group-item-action ${
                                selectedTenant?.id === tenant.id ? 'active' : ''
                            }`}
                            onClick={() => setSelectedTenant(tenant)}
                        >
                            {tenant.full_name} | {tenant.room.room_number}
                        </button>
                    ))}
                    {filteredTenants.length > 5 && (
                        <div className="text-muted mt-2 small">
                            +{filteredTenants.length - 5} more tenants match your search
                        </div>
                    )}
                    {filteredTenants.length === 0 && (
                        <div className="text-muted mt-2">
                            No tenants found matching your search
                        </div>
                    )}
                </div>
            </div>
            <div className="mb-3">
                    <label htmlFor="newRoom" className="form-label">New Room</label>
                    <select 
                        id="newRoom" 
                        className="form-select"
                        value={newRoom} 
                        onChange={(e) => setNewRoom(e.target.value)}
                    >
                        <option value="">Select a new room</option>
                        {rooms && rooms.map(room => (
                            <option key={room} value={room}>
                                {room}
                            </option>
                        ))}
                    </select>
                </div>




                <div className="mb-3">
                    <label htmlFor="transferReason" className="form-label">Reason for Transfer</label>
                    <select 
                        id="transferReason" 
                        className="form-select"
                        value={transferReason} 
                        onChange={(e) => setTransferReason(e.target.value)}
                    >
                        <option value="">Select a reason</option>
                        <option value="personal">Personal</option>
                        <option value="maintenance">Maintenance Issue</option>
                        <option value="upgrade">Upgrade</option>
                        <option value="other">Other</option>
                    </select>
                </div>

                <div className="mb-3">
                    <label htmlFor="transferDate" className="form-label">Date of Transfer</label>
                    <input 
                        type="date" 
                        id="transferDate" 
                        className="form-control"
                        value={transferDate} 
                        onChange={(e) => setTransferDate(e.target.value)}
                    />
                </div>


                <button 
                    type="submit" 
                    className={`btn btn-primary w-100`}
                    disabled={!selectedTenant || !newRoom || !transferReason || !transferDate || isSubmitting}
                >
                    {isSubmitting ? 'Transferring...' : 'Transfer Tenant'}
                </button>
            </form>


          

        </div>
    );
}

export default TransferTenant;
