import React from "react";
import './Landing.css'; // Import the CSS file for custom styles
import { NavLink } from "react-router-dom";
import Logo from '../assets/sun.png'
import one from '../assets/img1.jpeg'
import two from '../assets/img2.jpeg'
import three from '../assets/img3.jpeg'
import four from '../assets/img4.jpeg'
import five from '../assets/img5.jpeg'

import { Container, Row, Col, Card, Badge } from 'react-bootstrap';
import { Wifi, Shield, Tv, Sun, Leaf, Droplet as WaterDrop } from 'lucide-react';
const LandingPage = () => {
    return (
        <>
            <div className="landing-page d-flex align-items-center justify-content-center">
                <img src={Logo} alt="Logo" className="top-center-logo" />
                <div className="content-container d-flex adaptive">
                    <div className="text-container p-5 text-left">
                        <h1 className="animated-text">Karibu home</h1>
                        <h5 className="tagline">Providing affordable student accommodation</h5>
                        <NavLink to="/auth/login" className="styled-navlink">
                            <h5 className="sign-in">Sign in</h5>

                        </NavLink>
                    </div>
                </div>

            </div>
            <Container className="py-5 text-center">
                <h2 className="mb-4 text-secondary fw-bold">Your Ideal Academic Haven</h2>
                <Row className="g-4">
                    <Col md={4}>
                        <Card className="h-100 border-0 shadow-lg transform-hover">
                            <Card.Body className="d-flex flex-column align-items-center">
                                <Wifi className="text-primary mb-3" size={48} />
                                <Card.Title className="mb-3">Wifi Connectivity</Card.Title>
                                <Card.Text className="text-muted">
                                    Lightning-fast, reliable  WiFi that keeps you connected 24/7
                                    <Badge bg="success" className="ms-2">Ultra-Fast</Badge>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={4}>
                        <Card className="h-100 border-0 shadow-lg transform-hover">
                            <Card.Body className="d-flex flex-column align-items-center">
                                <Shield className="text-primary mb-3" size={48} />
                                <Card.Title className="mb-3">Safety First</Card.Title>
                                <Card.Text className="text-muted">
                                    Secure, spacious rooms designed with student safety in mind
                                    <Badge bg="warning" className="ms-2">Comfort Zones</Badge>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={4}>
                        <Card className="h-100 border-0 shadow-lg transform-hover">
                            <Card.Body className="d-flex flex-column align-items-center">
                                <Leaf className="text-primary mb-3" size={48} />
                                <Card.Title className="mb-3">Green Relaxation</Card.Title>
                                <Card.Text className="text-muted">
                                    Clean, eco-friendly relaxation areas to recharge and unwind
                                    <Badge bg="info" className="ms-2">Zen Spaces</Badge>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={4}>
                        <Card className="h-100 border-0 shadow-lg transform-hover">
                            <Card.Body className="d-flex flex-column align-items-center">
                                <WaterDrop className="text-primary mb-3" size={48} />
                                <Card.Title className="mb-3">Water Wisdom</Card.Title>
                                <Card.Text className="text-muted">
                                    Reliable water supply with strategic hot water for early risers
                                    <Badge bg="secondary" className="ms-2">Smart Supply</Badge>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={4}>
                        <Card className="h-100 border-0 shadow-lg transform-hover">
                            <Card.Body className="d-flex flex-column align-items-center">
                                <Tv className="text-primary mb-3" size={48} />
                                <Card.Title className="mb-3">Entertainment Zones</Card.Title>
                                <Card.Text className="text-muted">
                                    Communal TV room for relaxation, sports and social connections
                                    <Badge bg="danger" className="ms-2">Chill Spot</Badge>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={4}>
                        <Card className="h-100 border-0 shadow-lg transform-hover">
                            <Card.Body className="d-flex flex-column align-items-center">
                                <Sun className="text-primary mb-3" size={48} />
                                <Card.Title className="mb-3">Laundry Luxury</Card.Title>
                                <Card.Text className="text-muted">
                                    Spacious, modern laundry facilities for hassle-free living
                                    <Badge bg="primary" className="ms-2">Clean Living</Badge>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <div class="container mt-5">
                {/* <h2 className="text-center mb-4">About us</h2> */}
                <h5 class="text-center mb-4 text-secondary">Thank you for choosing sunshine residence as your home.</h5>
                <p className='text-secondary'>
                    contact us: +254 748439084 | email: sunshinehostbondo@gmail.com
                </p>
                <div id="galleryCarousel" class="carousel slide" data-bs-ride="carousel">
                    <div class="carousel-inner">
                        <div class="carousel-item active">
                            <img src={two} class="d-block w-100" alt=" 1" />
                        </div>
                        <div class="carousel-item active">
                            <img src={five} class="d-block w-100" alt=" 1" />
                        </div>
                        <div class="carousel-item">
                            <img src={one} class="d-block w-100" alt=" 2" />
                        </div>
                        <div class="carousel-item rounded">
                            <img src={three} class="d-block w-100" alt="3" />
                        </div>
                        <div class="carousel-item rounded">
                            <img src={four} class="d-block w-100" alt="3" />
                        </div>
                    </div>
                    <button class="carousel-control-prev " type="button" data-bs-target="#galleryCarousel" data-bs-slide="prev">
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Previous</span>
                    </button>
                    <button class="carousel-control-next" type="button" data-bs-target="#galleryCarousel" data-bs-slide="next">
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Next</span>
                    </button>
                </div>

                <div class="row mt-4">
                <div class="col-md-4 mb-3 gallery-item">
                        <img src={five} class="w-100 shadow-sm" alt="" />
                    </div>
                <div class="col-md-4 mb-3 gallery-item">
                        <img src={four} class="w-100 shadow-sm" alt="" />
                    </div>
                    <div class="col-md-4 mb-3 gallery-item">
                        <img src={three} class="w-100 shadow-sm" alt="" />
                    </div>
                    <div class="col-md-4 mb-3 gallery-item">
                        <img src={two} class="w-100 shadow-sm" alt="" />
                    </div>
                    <div class="col-md-4 mb-3 gallery-item">
                        <img src={one} class="w-100 shadow-sm" alt="" />
                    </div>
                </div>
            </div>
        </>

    );
};

export default LandingPage;
