import  { useEffect, useRef } from 'react';
import {  ref, onValue,  } from 'firebase/database';
import { useNotification } from '../context/NotificationContext';
import useAuth from '../hooks/useAuth';
import { database } from '../config/firebaseConfig';


const ChatNotificationListener = () => {
  const { user } = useAuth();
  const { showNotification } = useNotification();
  const lastMessageRef = useRef({});

  useEffect(() => {
    if (!user) return;

    // Create a ref to all chats
    const chatsRef = ref(database, 'chats');

    // Set up listener for all chats
    const unsubscribe = onValue(chatsRef, (snapshot) => {
      const chatsData = snapshot.val();
      if (!chatsData) return;

      // Iterate through all chat rooms
      Object.entries(chatsData).forEach(([chatRoomId, chatRoomData]) => {
        // Check if this chat room is relevant to the current user
        if (chatRoomId.includes(`${user.first_name}_${user.last_name || ''}`) || 
            chatRoomId.includes(`${user.first_name}_`)) {
          
          // Check messages in this chat room
          const messages = chatRoomData.messages || {};
          
          Object.entries(messages).forEach(([messageId, message]) => {
            // Avoid notifying about own messages
            if (message.senderId !== user.first_name) {
              // Create a unique key for tracking last seen message
              const notificationKey = `${chatRoomId}_${messageId}`;
              
              // Check if this is a new message
              if (!lastMessageRef.current[notificationKey]) {
                // Show notification
                showNotification(
                  `New message from ${message.senderName}: ${message.text}`,
                  "info"
                );
                
                // Mark this message as seen
                lastMessageRef.current[notificationKey] = true;
              }
            }
          });
        }
      });
    });

    // Cleanup subscription
    return () => unsubscribe();
  }, [user, showNotification]);

  // This component doesn't render anything
  return null;
};

export default ChatNotificationListener;