import React from "react";
import { NavLink } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import { Home, User, LogIn, UserPlus, PlusCircle, Heart, ShoppingBasketIcon } from "lucide-react";
import './navbar.css'; 

export default function Navbar() {
  const { isLoggedIn, user } = useAuth();

  return (
    <nav className="navigation-container">
      <div className="nav-content">
        <NavLink className="nav-link" to="/">
          <Home  />
          <span>Home</span>
        </NavLink>
        <NavLink className="nav-link" to="/services">
          <ShoppingBasketIcon/>
          <span>Services</span>
        </NavLink>
        <NavLink className="nav-link" to="/advertise">
          <PlusCircle />
          <span>Advertise</span>
        </NavLink>
        <NavLink className="nav-link" to="/about">
          <Heart />
          <span>About us</span>
        </NavLink>
        
        {isLoggedIn ? (
          <NavLink className="nav-link" to="/auth/user">
            <User />
            <span>{user.first_name}</span>
          </NavLink>
        ) : (
          <>
            <NavLink className="nav-link" to="/auth/login">
              <LogIn />
              <span>Login</span>
            </NavLink>
            <NavLink className="nav-link" to="/auth/register">
              <UserPlus />
              <span>Register</span>
            </NavLink>
          </>
        )}
      </div>
    </nav>
  );
}
