import React, { useState } from 'react';
import usePayments from '../hooks/usePayments';
import Loading from './Loading';

export default function PaymentStatus() {
    const { payments, loading,   } = usePayments();
    const [showPayments, setShowPayments] = useState(false);

    if (loading) {
        return <Loading />;
    }

    const togglePayments = () => {
        setShowPayments(!showPayments);
    };

    return (
        <div className="h-100 bg-light p-4 rounded">
            <div className="p-2">
                <div>
                    <h3 className='fw-bold' style={{color:"#083508"}}>Room {payments?.room_number}</h3>
                    <p>Rate: sh {payments?.rate}/month</p>
                    <p>Current month balance: sh {payments?.current_month_balance}</p>
                </div>
                <button onClick={togglePayments} className="btn btn-outline-secondary mb-2">
                    {showPayments ? 'Hide Payments' : 'Show Payments'}
                </button>
                {showPayments && (
                    <>
                        <h6>Recent room payments</h6>
                        <p className='small'>Missing payments? <br /> contact us.</p>
                        {payments?.matched_payments.map((payment, index) => {
                            const date = new Date(payment.date_time);
                            const formattedDate = `${date.getFullYear()}/${String(date.getMonth() + 1).padStart(2, '0')}/${String(date.getDate()).padStart(2, '0')} ${String(date.getHours()).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')}`;

                            return (
                                <p key={index}>
                                    {payment.transaction_id} Ksh.{payment.amount}. Date: {formattedDate}
                                </p>
                            );
                        })}
                    </>
                )}
            </div>
        </div>
    );
}

