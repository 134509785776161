import React from 'react';
import useAccount from '../hooks/useAccount';

export default function AccountNumber() {
    const { account, loading, error } = useAccount();

    return (
        <div className="h-100 bg-light">
            <div className="p-2">
                {loading ? (
                    <p>Loading...</p>
                ) : error ? (
                    <p className="text-danger">{error}</p>  
                ) : (
                    <>
                        <h5>Acc. no. {account?.account_number}</h5>
                        <p>Make payments to sunshine using this account number.</p>
                    </>
                )}
            </div>
        </div>
    );
}
