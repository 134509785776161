import { Routes, Navigate, Route } from 'react-router-dom'
import AuthMiddleware from './middlewares/AuthMiddleware';
import Login from './pages/auth/Login';
import Register from './pages/auth/Register';
import Home from './pages/Home';
import User from './pages/auth/User'
import PersistLogin from './components/PersistLogin';
import PostDetail from './pages/blog/PostDetail';
import Services from './pages/Services';
import Advertise from './pages/Advertise';
import About from './pages/About';
import { NotificationProvider } from './context/NotificationContext';
import ChatNotificationListener from './components/ChatNotificationListener';
function App() {
  return <NotificationProvider>
    <ChatNotificationListener/>
    <Routes>
      <Route path='/' element={<PersistLogin />}>
        <Route index exact element={<Home />}/>
        <Route path='/services' element={<Services />}/>
        <Route path='/advertise' element={<Advertise />}/>
        <Route path='/about' element={<About />}/>
        <Route path='/post/:id' element={<PostDetail />}/>
        <Route path='/auth'>
          <Route path='login' element={<Login />}></Route>
          <Route path='register' element={<Register />}></Route>
          <Route path='user' element={<AuthMiddleware />}>
            <Route index element={<User />}></Route>
          </Route>
        </Route>
      </Route>
      <Route path='*' element={<Navigate to='/' />}></Route>
    </Routes>
  </NotificationProvider>
}

export default App;
