import React from "react";
import Navbar from "./Navbar";
import "./Layout.css"; 

export default function Layout({ children }) {
  return (
    <div className="app-container">
      <Navbar />
      <main className="main-content">
        {children} 
      </main>
      <footer className="footer mt-4 text-center" style={{minHeight: "50px"	}}>
        <p>&copy; 2024 Karibu Mashtakimuni. All rights reserved.</p>
      </footer>
    </div>
  );
}
