import React, { useState } from 'react';
import { usePostContext } from '../context/PostContext'; // Import the context
import './PostForm.css'; // Import custom CSS for additional styling

const PostForm = () => {
  const { loading, error, addPost } = usePostContext(); // Destructure from context
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [image, setImage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [showToast, setShowToast] = useState(false);
  
  const clearForm = () => {
    setTitle('');
    setDescription('');
    setImage(null);
    setSuccessMessage('');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    const formData = new FormData();
    formData.append('title', title);
    formData.append('description', description);
    if (image) formData.append('image', image);

    try {
      await addPost(formData); // Use addPost from context
      setSuccessMessage('Your post is under review and will appear soon after approval!');
      clearForm();
      setShowToast(true); 
    } catch (error) {
      console.error("Error submitting post:", error);
    }
  };
  return (
    <div className="container mt-5">
      <form className='p-4 bg-light rounded shadow' onSubmit={handleSubmit}>
        <h2 className="text-center mb-4">Advertise with Us</h2>
        {loading && <p className="text-warning">Loading...</p>}
        {error && <p className="text-danger">{error}</p>}
        {successMessage && <p className="text-success">{successMessage}</p>}
        
        <div className="mb-3">
          <label htmlFor="title" className="form-label">Title</label>
          <input 
            type="text" 
            className="form-control" 
            id="title" 
            value={title} 
            onChange={(e) => setTitle(e.target.value)} 
            required 
          />
        </div>

        <div className="mb-3">
          <label htmlFor="description" className="form-label">Description</label>
          <textarea
            className="form-control"
            id="description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            required
          />
        </div>

        <div className="mb-3">
          <label htmlFor="image" className="form-label">Image</label>
          <input 
            type="file" 
            className="form-control" 
            id="image" 
            onChange={(e) => {
              if (e.target.files[0]) {
                setImage(e.target.files[0]);
              }
            }} 
          />
          {image && (
            <div className="mt-2 text-center">
              <img src={URL.createObjectURL(image)} alt="Selected" className="img-thumbnail preview-img" />
            </div>
          )}
          {!image && (
            <div className="placeholder-image mt-2 text-center">
              <span>Select an image</span>
            </div>
          )}
        </div>

        <button type="submit" className="btn btn-primary w-100">
          Send Post
        </button>
      </form>

      {showToast && (
        <div className="toast show position-fixed top-0 end-0 m-3" role="alert" aria-live="assertive" aria-atomic="true">
          <div className="toast-header">
            <strong className="me-auto text-success">Post Notification</strong>
            <small>Just now</small>
            <button type="button" className="btn-close" data-bs-dismiss="toast" aria-label="Close" onClick={() => setShowToast(false)}></button>
          </div>
          <div className="toast-body">
            <p>Your post is under review and will appear soon after approval.</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default PostForm;