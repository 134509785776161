import React from 'react';
import Layout from '../components/layout/Layout';
import PostForm from '../components/PostForm';
function Advertise(props) {
    return (
        <Layout>
            <PostForm/>
        </Layout>
    );
}

export default Advertise;