import React, { createContext, useState, useContext, useEffect, useCallback } from 'react';
import { axiosPrivateInstance } from '../api/apiConfig';

const PostContext = createContext();

export const usePostContext = () => useContext(PostContext);

export const PostProvider = ({ children }) => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchPosts = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await axiosPrivateInstance.get('/blog/posts/');
      setPosts(response.data);
    } catch (err) {
      setError('Failed to fetch posts. Please try again later.');
      console.error('Error fetching posts:', err);
    } finally {
      setLoading(false);
    }
  }, []); 

  useEffect(() => {
    fetchPosts();
  }, [fetchPosts]);

  const addPost = async (newPost) => {
    setLoading(true);
    try {
      const response = await axiosPrivateInstance.post('/blog/posts/', newPost);
      setPosts(prevPosts => [response.data, ...prevPosts]);
      return response.data;
    } catch (err) {
      setError('Failed to add post. Please try again.');
      console.error('Error adding post:', err);
      throw err;
    } finally {
      setLoading(false);
    }
  };

  const updatePost = async (id, updatedPost) => {
    setLoading(true);
    try {
      const response = await axiosPrivateInstance.put(`/blog/posts/${id}/`, updatedPost, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setPosts(prevPosts => prevPosts.map(post => (post.id === id ? response.data : post)));
      return response.data;
    } catch (err) {
      setError('Failed to update post. Please try again.');
      console.error('Error updating post:', err);
      throw err;
    } finally {
      setLoading(false);
    }
  };

  const deletePost = async (id) => {
    setLoading(true);
    try {
      await axiosPrivateInstance.delete(`/blog/posts/${id}/`);
      setPosts(prevPosts => prevPosts.filter(post => post.id !== id));
    } catch (err) {
      setError('Failed to delete post. Please try again.');
      console.error('Error deleting post:', err);
      throw err;
    } finally {
      setLoading(false);
    }
  };

  const value = {
    posts,
    setPosts,
    loading,
    error,
    fetchPosts,
    addPost,
    updatePost,
    deletePost,
  };

  return <PostContext.Provider value={value}>{children}</PostContext.Provider>;
};
