import React, { useEffect } from 'react'
import useAuth from '../hooks/useAuth'
import useUser from '../hooks/useUser'
import LandingPage from './LandingPage'
import PaymentStatus from '../components/PaymentStatus'
import AccountNumber from '../components/AccountNumber'
import RemoveTenant from '../components/RemoveTenant'
import "./Home.css"
import Layout from '../components/layout/Layout'
import Logo from "../assets/sun.png"
import TransferTenant from '../components/TransferTenant'
export default function Home() {
    const { user } = useAuth()
    const getUser = useUser()

    useEffect(() => {
        getUser()
    }, [getUser])
    if (!user?.email) {
        return <LandingPage />
    }
    return (
        <Layout>

            <div
                className="container  text-center d-flex justify-content-start rounded"
                style={{
                    background: "linear-gradient(to bottom right, #041f04, #f9fcf8 50%)",
                    width: "100%",
                    height: "100%",
                }}
            >
                <img className='logo-image' src={Logo} alt="sunshine" width={200} height={120} />
                <h1 style={{ fontFamily: 'Poppins, sans-serif' }} className="my-4">
                    Welcome Back
                </h1>
            </div>

            {user?.email && (
                <div className='row'>
                    <div className="col-md-9">
                        {user.is_staff ?<> <TransferTenant/><RemoveTenant />
                        </>  : <PaymentStatus />}
                    </div>
                    <div className="col-md-9 mt-4">

                        {user.is_staff ? null : <AccountNumber />}
                    </div>
                   
                </div>
            )}
        </Layout>
    )
}