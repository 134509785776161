import { useState, useEffect, useCallback } from "react";
import useAuth from "./useAuth";
import useAxiosPrivate from "./usePrivate";

export default function useRooms() {
    const { isLoggedIn } = useAuth();
    const axiosPrivateInstance = useAxiosPrivate();
    
    const [rooms, setRooms] = useState(null);
    const [loading, setLoading] = useState(false);
    const [roomError, setRoomError] = useState(null);

    const getRooms = useCallback(async () => {
        if (!isLoggedIn) {
            return;
        }

        setLoading(true);
        setRoomError(null); // Reset roomError state before fetching

        try {
            const { data } = await axiosPrivateInstance.get('onboard/room-numbers/');
            setRooms(data);
        } catch (error) {
            console.error("Error fetching rooms:", error.response);
            if (error.response?.status === 404) {
                setRoomError('Rooms not found');
            } else {
                setRoomError(error.response?.data || "An error occurred while fetching rooms.");
            }
        } finally {
            setLoading(false);
        }
    }, [isLoggedIn, axiosPrivateInstance]); // Add dependencies here

    useEffect(() => {
        if (isLoggedIn) {
            getRooms();
        }
    }, [isLoggedIn, getRooms]);

    return { rooms, loading, roomError, getRooms };
}