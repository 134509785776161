import { useState, useEffect } from "react";
import useAuth from "./useAuth";
import useAxiosPrivate from "./usePrivate";

export default function usePayments() {
    const { isLoggedIn } = useAuth();
    const axiosPrivateInstance = useAxiosPrivate();
    
    const [payments, setPayments] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    async function getPayments() {
        if (!isLoggedIn) {
            return;
        }

        setLoading(true);
        setError(null); // Reset error state before fetching

        try {
            const { data } = await axiosPrivateInstance.get('onboard/student_room_paymments/');
            setPayments(data);
        } catch (error) {
            console.error("Error fetching payments:", error.response);
            setError(error.response?.data || "An error occurred while fetching payments.");
        } finally {
            setLoading(false);
        }
    }

    // Optionally fetch payments when the component mounts or when isLoggedIn changes
    useEffect(() => {
        if (isLoggedIn) {
            getPayments();
        }
    }, [isLoggedIn]);

    return { payments, loading, error, getPayments };
}