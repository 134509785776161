import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import useLogout from '../../hooks/useLogout';
import useUser from '../../hooks/useUser';
import useAxiosPrivate from '../../hooks/usePrivate';
import { Edit, Loader, Save, X } from 'lucide-react';
import Layout from '../../components/layout/Layout';

export default function User() {
    const { user, setUser } = useAuth();
    const navigate = useNavigate();
    const logout = useLogout();
    const [loading, setLoading] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [formData, setFormData] = useState({
        first_name: user?.first_name || '',
        last_name: user?.last_name || '',
        email: user?.email || '',
        password: '', // New password field
    });
    const getUser = useUser();
    const axiosPrivate = useAxiosPrivate();
    
    useEffect(() => {
        getUser();
    }, [getUser]);

    async function onLogout() {
        setLoading(true);
        await logout();
        navigate('/');
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleProfileUpdate = async (e) => {
        e.preventDefault();
        try {
            const response = await axiosPrivate.put('auth/user/update', formData);
            setUser(response.data);
            setEditMode(false);
            setSuccessMessage('Profile updated successfully!');
            setTimeout(() => setSuccessMessage(''), 3000); // Clear message after 3 seconds
        } catch (error) {
            console.error('Error updating profile:', error);
        }
    };

    const LoadingSpinner = () => {
        return <Loader size={24} color="blue" />;
    };

    return (
        <Layout>
        <div className="container mt-5 d-flex justify-content-center">
            <div className="card shadow-sm p-4" style={{ width: '400px', borderRadius: '12px' }}>
                <div className="text-center mb-4">
                    <img
                        className="object-fit-cover rounded-circle mb-3"
                        src={user.profile_image ? user.profile_image : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"}
                        alt="Profile"
                        style={{ width: '100px', height: '100px' }}
                    />
                    <h4>{user?.username || 'Username'}</h4>
                    <p className="text-muted mb-1">{user?.email || 'Email not available'}</p>
                </div>

                {editMode ? (
                    <form onSubmit={handleProfileUpdate}>
                        <div className="form-group mb-3">
                            <label>First name</label>
                            <input type="text" className="form-control" name="first_name" value={formData.first_name} onChange={handleInputChange} required />
                        </div>
                        <div className="form-group mb-3">
                            <label>Last name</label>
                            <input type="text" className="form-control" name="last_name" value={formData.last_name} onChange={handleInputChange} required />
                        </div>
                        <div className="form-group mb-3">
                            <label>Email</label>
                            <input type="email" className="form-control" name="email" value={formData.email} onChange={handleInputChange} required />
                        </div>
                        <div className="form-group mb-3">
                            <label>Password (optional)</label>
                            <input type="password" className="form-control" name="password" value={formData.password} onChange={handleInputChange} placeholder="New password" />
                        </div>
                        {/* Save and Cancel buttons */}
                        <div className="d-flex justify-content-between">
                            <div className="mb-3">
                                <button className='btn  btn-light ' type="button" onClick={() => setEditMode(false)}>
                                    <X className="mr-2" /> Cancel
                                </button>
                            </div>
                            <div className="mb-3">
                                <button disabled={loading} className='btn  btn-light' type="submit">
                                    <Save className="mr-2" /> Save
                                </button>
                            </div>
                        </div>
                    </form>
                ) : (
                    <>
                        <ul className="list-group list-group-flush mb-3">
                            <li className="list-group-item"><strong>First Name:</strong> {user?.first_name || 'N/A'}</li>
                            <li className="list-group-item"><strong>Last Name:</strong> {user?.last_name || 'N/A'}</li>
                        </ul>
                        <div className="text-center mb-3">
                            <button className="btn  w-100" onClick={() => setEditMode(true)}>
                                <Edit className="mr-2" />
                                <i className="fas fa-edit"></i> Edit Profile
                            </button>
                        </div>
                    </>
                )}
                {successMessage && <div className="alert alert-success text-center">{successMessage}</div>}
                <div className="text-center">
                    {loading ? (
                        <LoadingSpinner />
                    ) : (
                        <button className="btn btn-light w-100" onClick={onLogout}>
                            <i className="fas fa-sign-out-alt"></i> Logout
                        </button>
                    )}
                </div>
            </div>
        </div>
        </Layout>
    );
}
