import React from 'react';
import Layout from '../components/layout/Layout';
import './About.css'
import one from '../assets/img1.jpeg'	
import two from '../assets/img2.jpeg'	
import three from '../assets/img3.jpeg'

function About(props) {
    return (
        <Layout>
             <div class="container mt-5">
        <h2 className="text-center mb-4">We are Glad</h2>
        <h5 class="text-center mb-4 text-secondary">Thank you for choosing sunshine residence as your home.</h5>
        <p className='text-secondary'>
            contact us: +254 748439084 | email: sunshinehostbondo@gmail.com
        </p>
        <div id="galleryCarousel" class="carousel slide" data-bs-ride="carousel">
            <div class="carousel-inner">
                <div class="carousel-item active">
                    <img src={two} class="d-block w-100" alt=" 1"/>
                </div>
                <div class="carousel-item">
                    <img src={one} class="d-block w-100" alt=" 2"/>
                </div>
                <div class="carousel-item rounded">
                    <img src={three} class="d-block w-100" alt="3"/>
                </div>
            </div>
            <button class="carousel-control-prev" type="button" data-bs-target="#galleryCarousel" data-bs-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#galleryCarousel" data-bs-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Next</span>
            </button>
        </div>

        <div class="row mt-4">
            <div class="col-md-4 mb-3 gallery-item">
                <img src={three} class="w-100 shadow-sm" alt=""/>
            </div>
            <div class="col-md-4 mb-3 gallery-item">
                <img src={two} class="w-100 shadow-sm" alt=""/>
            </div>
            <div class="col-md-4 mb-3 gallery-item">
                <img src={one} class="w-100 shadow-sm" alt=""/>
            </div>
        </div>
    </div>
        </Layout>
    );
}

export default About;