import React, { useState, useEffect, useRef,useCallback } from 'react';
import { Edit, Trash2, Save, Image as ImageIcon, ArrowLeft, Edit2, XIcon } from 'lucide-react';
import { useParams, useNavigate } from 'react-router-dom';
import { axiosPrivateInstance } from '../../api/apiConfig';
import useAuth from '../../hooks/useAuth';
import CommentSection from '../../components/comments/CommentSection';
import { usePostContext } from '../../context/PostContext';
import Loading from '../../components/Loading';
import "./PostList"
import Layout from "../../components/layout/Layout"
import ChatComponent from '../../components/ChatComponent';
const PostDetail = () => {
    const [post, setPost] = useState(null);
    const [comments, setComments] = useState([]);
    const [isEditing, setIsEditing] = useState(false);
    const [editedPost, setEditedPost] = useState({});
    const [newImage, setNewImage] = useState(null);
    const [loading, setLoading] = useState()
    const fileInputRef = useRef(null);
    const { id } = useParams();
    const { user,isLoggedIn } = useAuth();
    const navigate = useNavigate();
    const { deletePost, updatePost } = usePostContext();
    const fetchPostDetails = useCallback(async () => {
        if (!isLoggedIn) {
            return;
        }
        setLoading(true);
        try {
            const response = await axiosPrivateInstance.get(`/blog/posts/${id}/`);
            setPost(response.data);
            setComments(response.data.comments);
            setEditedPost(response.data);
        } catch (error) {
            console.error('Error fetching post details:', error);
        } finally {
            setLoading(false);
        }
    }, [id, isLoggedIn]); // Add dependencies here

    useEffect(() => {
        if (post) return;
        fetchPostDetails();
    }, [fetchPostDetails, post]); // Include fetchPostDetails and post


    const handleDelete = async () => {
        if (window.confirm('Are you sure you want to delete this post?')) {
            try {
                await deletePost(id);
                navigate('/services');
            } catch (error) {
                console.error('Error deleting post:', error);
            }
        }
    };

    const handleEdit = () => {
        setIsEditing(true);
    };


    const handleSaveEdit = async () => {
        setLoading(true)
        try {
            const formData = new FormData();
            formData.append('title', editedPost.title);
            formData.append('description', editedPost.description);
            if (newImage) {
                formData.append('image', newImage);
            }
            const response = await updatePost(id, formData);
            setPost(response);
            setIsEditing(false);
            setNewImage(null);
            setLoading(false)
        } catch (error) {
            console.error('Error updating post:', error);
        }
    };

    const handleInputChange = (e) => {
        setEditedPost({ ...editedPost, [e.target.name]: e.target.value });
    };

    const handleImageChange = (e) => {
        if (e.target.files[0]) {
            setNewImage(e.target.files[0]);
        }
    };

    const triggerFileInput = () => {
        fileInputRef.current.click();
    };

    if (!post || loading) return (
        <div className='container mt-4 text-center'>
            <Loading />
        </div>
    );

    return (
        <Layout>
            <div className=" mt-4 ">
                <button className="btn  mb-3 " onClick={() => navigate(-1)}>
                    <ArrowLeft size={24} className="me-1" /> back
                </button>
                {user.id === post.author_ID && (
                    !isEditing ? (
                        <button className="btn  mb-3 text-muted " onClick={() => handleEdit()}>
                            <Edit2 size={20} className="me-1" /> edit post
                        </button>
                    ) : (
                        <>
                             <button className="btn mb-3 text-danger" onClick={handleDelete}>
                                    <Trash2 size={18} className="me-1" /> Delete post
                            </button>
                            <button className="btn  mb-3 text-muted " onClick={() => setIsEditing(false)}>
                                <XIcon size={20} className="me-1" /> cancel edit
                            </button>
                            <button className=" btn  mb-3 text-muted" onClick={triggerFileInput}>
                                <ImageIcon size={18} className="me-1" />change image
                            </button>
                            <button className="btn  mb-3 " onClick={handleSaveEdit}>
                                <Save size={18} className="me-1" />save
                            </button>
                           
                        </>

                    )


                )}

                {isEditing ? (
                    <div className="d-flex flex-column align-items-center">
                        <div class="input-group mb-2" style={{ justifyContent: 'center' }}>
                            <span class="input-group-text">Title</span>
                            <input type="text" name="title" value={editedPost.title} style={{ maxWidth: '480px' }}
                                onChange={handleInputChange} aria-label="title" class="form-control " />
                        </div>
                      
                        <textarea
                            name="description"
                            value={editedPost.description}
                            onChange={handleInputChange}
                            className="form-control  w-50"
                            style={{ minWidth: '340px' }}
                        />
                        <div className="mb-3">

                            <input
                                type="file"
                                ref={fileInputRef}
                                onChange={handleImageChange}
                                style={{ display: 'none' }}
                                accept="image/*"
                            />



                        </div>
                        {newImage && <span className="ms-2 fw-bold">New image: {newImage.name}</span>}
                        {(post.image_url || newImage) && (
                            <img
                                src={newImage ? URL.createObjectURL(newImage) : post.image_url}
                                className="img-fluid mb-3 responsive-image rounded"
                                alt={post.title}
                                style={{ maxHeight: '800px' }}
                            />
                        )}
                    </div>
                ) : (
                    <div className="d-flex flex-column flex-md-row" style={{ justifyContent: 'center' }}>
                        <div className="text-center me-md-4">
                            <h2>{post.title}</h2>
                            {post.image_url &&
                                <img src={post.image_url} className="img-fluid mb-3 responsive-image rounded" style={{ maxHeight: '500px' }} alt={post.title} />}
                            <p>{post.description}</p>
                            <p><small className="text-muted">By {post.author} on {new Date(post.created_at).toLocaleDateString()}</small></p>

                            {user.email === post.author && (
                                <div>
                                    <button className="btn btn-primary me-2" onClick={handleEdit}>
                                        <Edit size={18} className="me-1" /> Edit
                                    </button>
                                   
                                </div>
                            )}
                        </div>
                        <ChatComponent postId={id} post={post} />
                    </div>
                )}
                <CommentSection postId={id} initialComments={comments} />

            </div>
        </Layout>


    );
};

export default PostDetail;