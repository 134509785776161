import React from 'react';
import Layout from '../components/layout/Layout';
import PostList from './blog/PostList';
function Services(props) {
    return (
     <Layout>   
        <PostList/>
        </Layout>
    );
}

export default Services;