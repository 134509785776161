import React, { useState } from 'react';
import { axiosPrivateInstance } from '../../api/apiConfig';
import useAuth from '../../hooks/useAuth';
import { Edit, Trash2, Save, X } from 'lucide-react';
// Comment List Component
const CommentList = ({ comments, onEditComment, onDeleteComment }) => {
  return (
    <ul className=" mt-3">
      {comments.map(comment => (
        <CommentItem
          key={comment.id}
          comment={comment}
          onEditComment={onEditComment}
          onDeleteComment={onDeleteComment}
        />
      ))}
    </ul>
  );
};

// Individual Comment Component
const CommentItem = ({ comment, onEditComment, onDeleteComment }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedContent, setEditedContent] = useState(comment.content);
  const { user } = useAuth();

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleCancelEdit = () => {
    setIsEditing(false);
    setEditedContent(comment.content);
  };

  const handleSaveEdit = () => {
    onEditComment(comment.id, editedContent);
    setIsEditing(false);
  };

  const handleDelete = () => {
    if (window.confirm('Are you sure you want to delete this comment?')) {
      onDeleteComment(comment.id);
    }
  };

  return (
    <li className="list-group-item">
      {isEditing ? (
        <>
          <textarea
            className="form-control mb-2"
            value={editedContent}
            onChange={(e) => setEditedContent(e.target.value)}
          />
          <button className="btn btn-sm  me-2" onClick={handleSaveEdit}>
            <Save size={16} className="me-1" /> Save
          </button>
          <button className="btn btn-sm " onClick={handleCancelEdit}>
            <X size={16} className="me-1" /> Cancel
          </button>
        </>
      ) : (
        <>
          <p>{comment.content}</p>
          <small className="text-muted ">
           <p className='small'> By {comment.author.split('@')[0]} on {new Date(comment.created_at).toLocaleDateString()}</p>
          </small>
          {user.email === comment.author && (
            <div className="mt-2 ">
              <button className="btn btn-sm  me-2 p-1" onClick={handleEdit}>
                <Edit size={16} className="me-1" /> Edit
              </button>
              <button className="btn btn-sm   " onClick={handleDelete}>
                <Trash2  size={16} className="" /> Delete
              </button>
            </div>
          )}
        </>
      )}
    </li>
  );
};

// Create Comment Component
const CreateComment = ({ postId, onCommentCreated }) => {
  const [content, setContent] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axiosPrivateInstance.post('/blog/comments/', { content, post: postId });
      onCommentCreated(response.data);
      setContent('');
    } catch (error) {
      console.error('Error creating comment:', error);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="mt-3">
      <textarea
        className="form-control mb-2"
        value={content}
        onChange={(e) => setContent(e.target.value)}
        placeholder="Write a comment..."
        required
      />
      <button type="submit" className="btn btn-primary">
        Post Comment
      </button>
    </form>
  );
};

// Parent Comment Component
const CommentSection = ({ postId, initialComments }) => {
  const [comments, setComments] = useState(initialComments);

  const handleCommentCreated = (newComment) => {
    setComments([...comments, newComment]);
  };

  const handleEditComment = async (commentId, newContent) => {
    try {
      const response = await axiosPrivateInstance.put(`/blog/comments/${commentId}/`, { content: newContent, post: postId });
      setComments(comments.map(comment => 
        comment.id === commentId ? { ...comment, content: response.data.content } : comment
      ));
    } catch (error) {
      console.error('Error editing comment:', error);
    }
  };

  const handleDeleteComment = async (commentId) => {
    try {
      await axiosPrivateInstance.delete(`/blog/comments/${commentId}/`);
      setComments(comments.filter(comment => comment.id !== commentId));
    } catch (error) {
      console.error('Error deleting comment:', error);
    }
  };

  return (
    <div className='d-flex justify-content-center' >
      <div className='col-7 ' >
      <h6 className='mt-4'>Comments</h6>
        <div className='comment-section' >
          <CommentList
            comments={comments}
            onEditComment={handleEditComment}
            onDeleteComment={handleDeleteComment}
          />
          <CreateComment postId={postId} onCommentCreated={handleCommentCreated} />
        </div>
      </div>
    </div>
  );
};
export default CommentSection;