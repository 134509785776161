import React, { useEffect } from 'react';
import {  MessageCircle, ArrowUpRight,  } from 'lucide-react';
import { Link } from 'react-router-dom';
import { usePostContext } from '../../context/PostContext';
import Loading from '../../components/Loading';
import './PostList.css';

const PostList = () => {
  const { posts, loading, error, fetchPosts } = usePostContext();

  useEffect(() => {
    fetchPosts();
  }, [fetchPosts, ]);

  if (loading) return <Loading />;
  if (error) return <div className="container">Error: {error}</div>;
  return (
    <div className="container">
      <h3 className="mt-16">Feed</h3>
      <hr />
      <div className="post-grid">
        {posts.length === 0 && <p >Hmmm... no posts yet!</p>}
        {posts?.map((post) => (
          <Link to={`/post/${post.id}`} key={post.id} className="post-card text-decoration-none bg-white rounded-lg shadow-md p-2 w-full max-w-md">
            <div className="flex items-start">
              <div className="author-info">
                <img
                  src={post.author_image ? post.author_image : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"}

                  alt="Profile"
                  className="author-image"
                />
                <div>
                  <br />
                  <p className="text-gray font-bold small m-0">{post.author}</p>
                  <p className="text-gray font-bold small">{new Date(post.created_at).toLocaleString()}</p>


                </div>
              </div>

              <div>
                <div className="flex items-center justify-between">
                  {/* <p className="text-gray font-bold small">{post.author}</p> */}
                  {/* <p className="text-gray-500 text-sm">{new Date(post.created_at).toLocaleString()}</p> */}
                </div>
                <p className="text-secondary-700 mb-2">{post.description}</p>
                {post.image_url && (
                  <img
                    src={post.image_url}
                    alt={post.title}
                    className="post-image mb-2"
                  />
                )}
                <div className="d-flex justify-content-between text-gray-500 text-sm">
                  <div className="flex items-center">
                    <MessageCircle size={16} className="mr-1" />
                    <span>{post.comments.length}</span>
                  </div>

                  <div className="flex items-center">
                    <ArrowUpRight size={16} className="mr-1" />
                  </div>

                </div>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default PostList;