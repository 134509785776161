import { initializeApp } from 'firebase/app';
import { getDatabase } from 'firebase/database';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
    apiKey: "AIzaSyDcMvKvNei5f_5a2RxiPA5EH3ImEhN5qC0",
   authDomain: "sunshinemsg-74b5c.firebaseapp.com",
   databaseURL: "https://sunshinemsg-74b5c-default-rtdb.asia-southeast1.firebasedatabase.app",
   projectId: "sunshinemsg-74b5c",
   storageBucket: "sunshinemsg-74b5c.appspot.com",
   messagingSenderId: "1997665001",
   appId: "1:1997665001:web:6e5120af28feb78cb9f678",
   measurementId: "G-25Q3CF2J0F"
  };

const app = initializeApp(firebaseConfig);

export const database = getDatabase(app);
export const auth = getAuth(app);

export default app;