import { useState, useEffect } from "react";
import useAuth from "./useAuth";
import useAxiosPrivate from "./usePrivate";

export default function useAccount() {
    const { isLoggedIn } = useAuth();
    const axiosPrivateInstance = useAxiosPrivate();
    
    const [account, setAccount] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    async function getAccounts() {
        if (!isLoggedIn) {
            return;
        }

        setLoading(true);
        setError(null); // Reset error state before fetching

        try {
            const { data } = await axiosPrivateInstance.get('onboard/account_number/');
            setAccount(data);
        } catch (error) {
            console.error("Error fetching account:", error.response);
            if (error.response?.status === 404) {
                setError('Student not found');
            } else {
                setError(error.response?.data || "An error occurred while fetching account.");
            }
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (isLoggedIn) {
            getAccounts();
        }
    }, [isLoggedIn]);

    return { account, loading, error, getAccounts };
}
