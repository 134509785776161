import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { axiosInstance } from '../../api/apiConfig';
import useAuth from '../../hooks/useAuth';

export default function Login() {
    const { setAccessToken, setCSRFToken, setIsLoggedIn } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const fromLocation = location?.state?.from?.pathname || '/';
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    function onEmailChange(event) {
        setEmail(event.target.value);
        setError('');
    }

    function onPasswordChange(event) {
        setPassword(event.target.value);
        setError('');
    }

    async function onSubmitForm(event) {
        event.preventDefault();

        if (!email || !password) {
            setError('Please enter both email and password.');
            return;
        }

        // Check if the email contains '@', and if not, append '@example.com'
        const formattedEmail = email.includes('@') ? email : `${email}@example.com`;

        setLoading(true);
        setError('');

        try {
            const response = await axiosInstance.post('auth/login', JSON.stringify({
                email: formattedEmail, // Use formattedEmail here
                password
            }));

            setAccessToken(response?.data?.access_token);
            setCSRFToken(response.headers["x-csrftoken"]);
            setIsLoggedIn(true);
            setEmail('');
            setPassword('');
            setLoading(false);

            navigate(fromLocation, { replace: true });
        } catch (error) {
            setLoading(false);
            setError(error.response?.data?.message || 'Invalid credentials. Please try again.');
        }
    }

    return (
        <div className='container'>
            <div className='row justify-content-center p-3'>
                <div className='col-md-8 col-lg-6 col-xl-5'>
                    <div className='card'>
                        <div className='card-header bg-white'>
                            <p className='small text-center'> Default username is your phone number, default password is id number provided in residency form. You can change this later in your profile</p>
                        </div>
                        <div className='card-body'>
                            <form onSubmit={onSubmitForm}>
                                <div className="mb-3">
                                    <label htmlFor="email" className="form-label">Username or email</label>
                                    <input 
                                        type="text" 
                                        placeholder='Email' 
                                        autoComplete='username' 
                                        className='form-control' 
                                        id="email" 
                                        value={email}
                                        onChange={onEmailChange} 
                                        required
                                    />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="password" className="form-label">Password</label>
                                    <input 
                                        type="password" 
                                        placeholder='Password' 
                                        autoComplete='current-password' 
                                        className='form-control' 
                                        id="password" 
                                        value={password}
                                        onChange={onPasswordChange} 
                                        required
                                    />
                                </div>
                                {error && <div className="alert alert-danger mb-3">{error}</div>}
                                <div className="mb-3">
                                    <button 
                                        disabled={loading} 
                                        className='btn btn-success w-100' 
                                        type="submit"
                                    >
                                        {loading ? 'checking...' : 'Login'}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
