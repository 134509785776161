import React, { useState, useEffect, useRef } from 'react';
import { ref, push, onValue, serverTimestamp} from 'firebase/database';
import { Send, Clock, Users, ChevronLeft } from 'lucide-react';
import { Button, Form, Card,  ListGroup } from 'react-bootstrap';
import useAuth from '../hooks/useAuth';
import { database } from '../config/firebaseConfig';

const generateChatRoomId = (user1FirstName, user1LastName, user2FirstName, user2LastName, postId = null) => {
  // Sort names alphabetically to ensure consistency
  const names = [
    `${user1FirstName}_${user1LastName}`, 
    `${user2FirstName}_${user2LastName}`
  ].sort();

  // Include post ID if provided, otherwise create a unique chat ID
  const postSuffix = postId ? `_${postId}` : '';
  return `chat_${names[0]}_${names[1]}${postSuffix}`;
};

// Separate component for message list
const MessageList = ({ messages, currentUser }) => {
  return (
    <div className="d-flex flex-column gap-3">
      {messages.map((message) => (
        <div
          key={message.id}
          className={`d-flex ${
            message.senderId === currentUser.first_name ? 'justify-content-end' : 'justify-content-start'
          }`}
        >
          <div
            className={`p-3 rounded ${
              message.senderId === currentUser.first_name
                ? 'bg-primary text-white'
                : 'bg-light'
            }`}
            style={{ maxWidth: '70%' }}
          >
            <div>{message.text}</div>
            <div className="small mt-1 d-flex align-items-center">
              <Clock size={12} className="me-1" />
              {new Date(message.timestamp).toLocaleTimeString()}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

// Separate component for user list
const UserChatList = ({ chatUsers, onUserSelect, selectedUserId }) => {
  return (
    <Card style={{ minWidth: '330px' }}>
      <Card.Header className="bg-light">
        <div className="d-flex align-items-center">
          <Users size={20} className="me-2" />
          <span className="fw-bold">Chats</span>
        </div>
      </Card.Header>
      <ListGroup variant="flush">
        {chatUsers.map((chatUser) => (
          <ListGroup.Item
            key={`${chatUser.first_name}_${chatUser.last_name}`}
            action
            active={selectedUserId === `${chatUser.first_name}_${chatUser.last_name}`}
            onClick={() => onUserSelect(chatUser)}
          >
            {`${chatUser.first_name} ${chatUser.last_name}`}
          </ListGroup.Item>
        ))}
        {chatUsers.length === 0 && (
          <ListGroup.Item>No chats yet</ListGroup.Item>
        )}
      </ListGroup>
    </Card>
  );
};

const ChatComponent = ({ postId, post }) => {
  const [messages, setMessages] = useState([]);
  const [chatUsers, setChatUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [newMessage, setNewMessage] = useState('');
  const messagesEndRef = useRef(null);
  const { user } = useAuth();
  const isPostOwner = user.id === post.author_ID;
  const [activeView, setActiveView] = useState('chats'); 


  // Scroll to bottom utility
  // const scrollToBottom = () => {
  //   messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  // };

  // Fetch all users who have chatted
  useEffect(() => {
    if (isPostOwner) {
      const chatsRef = ref(database, 'chats');
      onValue(chatsRef, (snapshot) => {
        const chatsData = snapshot.val();
        if (chatsData) {
          const uniqueUsers = new Set();
          Object.keys(chatsData).forEach(chatKey => {
            // Extract user names from chat key
            const parts = chatKey.split('_');
            if (parts[0] === 'chat' && parts.length >= 4) {
              const user1 = { first_name: parts[1], last_name: parts[2] };
              const user2 = { first_name: parts[3], last_name: parts[4] };
              
              // Add users to set, excluding current user
              if (user1.first_name !== user.first_name || user1.last_name !== user.last_name) {
                uniqueUsers.add(`${user1.first_name}_${user1.last_name}`);
              }
              if (user2.first_name !== user.first_name || user2.last_name !== user.last_name) {
                uniqueUsers.add(`${user2.first_name}_${user2.last_name}`);
              }
            }
          });
          
          // Convert to array of user objects
          const userArray = Array.from(uniqueUsers).map(userKey => {
            const [first_name, last_name] = userKey.split('_');
            return { first_name, last_name };
          });
          
          setChatUsers(userArray);
        }
      });
    } else {
      setActiveView('messages');
    }
  }, [isPostOwner, user.first_name, user.last_name]);

  // Listen to messages
  useEffect(() => {
    // Only set up listener if a user is selected or we're not the post owner
    if (!isPostOwner || selectedUser) {
      const chatRoomId = isPostOwner && selectedUser
        ? generateChatRoomId(
            selectedUser.first_name, 
            selectedUser.last_name, 
            user.first_name, 
            user.last_name, 
            postId
          )
        : generateChatRoomId(
            user.first_name, 
            user.last_name, 
            post.author, 
            post.author_last_name, 
            postId
          );

      const messagesRef = ref(database, `chats/${chatRoomId}/messages`);
      const unsubscribe = onValue(messagesRef, (snapshot) => {
        const messagesData = snapshot.val();
        const messagesList = messagesData
          ? Object.entries(messagesData).map(([key, value]) => ({
              id: key,
              ...value,
            }))
          : [];
        const sortedMessages = messagesList.sort((a, b) => a.timestamp - b.timestamp);

        // Avoid setting state if data is the same
        if (JSON.stringify(sortedMessages) !== JSON.stringify(messages)) {
          setMessages(sortedMessages);
        }
      });

      return () => unsubscribe();
    }
  }, [postId, user, selectedUser, isPostOwner, post,messages]);



  const sendMessage = (e) => {
    e.preventDefault();
    if (!newMessage.trim()) return;

    // Determine chat room ID based on whether post owner or regular user
    const chatRoomId = isPostOwner && selectedUser
      ? generateChatRoomId(
          selectedUser.first_name, 
          selectedUser.last_name, 
          user.first_name, 
          user.last_name, 
          postId
        )
      : generateChatRoomId(
          user.first_name, 
          user.last_name, 
          post.author, 
          post.author_last_name, 
          postId
        );

    const messagesRef = ref(database, `chats/${chatRoomId}/messages`);
    
    push(messagesRef, {
      text: newMessage,
      senderId: user.first_name,
      senderName: `${user.first_name} ${user.last_name}`,
      timestamp: serverTimestamp(),
    });
    
    setNewMessage('');
  };

  const handleUserSelect = (selectedChatUser) => {
    setSelectedUser(selectedChatUser);
    setActiveView('messages');
  };

  return (
    <div className="py-4" style={{ minWidth: '330px', marginTop: '16px' }}>
      <div className="d-flex">
        {activeView === 'chats' ? (
          isPostOwner && (
            <UserChatList 
              chatUsers={chatUsers} 
              onUserSelect={handleUserSelect}
              selectedUserId={selectedUser ? `${selectedUser.first_name}_${selectedUser.last_name}` : null}
            />
          )
        ) : (
          <Card className="flex-grow-1">
            <Card.Header className="bg-light d-flex align-items-center">
              {isPostOwner && <ChevronLeft size={20} className="me-2" onClick={() => setActiveView('chats')} />}
              
              <span className="fw-bold">
                {isPostOwner 
                  ? selectedUser 
                    ? `Talk to ${selectedUser.first_name} ${selectedUser.last_name}`
                    : 'Select a user to chat with'
                  : `Talk to ${post.author}`
                }
              </span>
            </Card.Header>
            
            <Card.Body style={{ height: '400px', overflowY: 'auto' }}>
              <MessageList messages={messages} currentUser={user} />
              <div ref={messagesEndRef} />
            </Card.Body>

            <Card.Footer className="bg-white border-top p-3">
              <Form onSubmit={sendMessage} className="d-flex gap-2">
                <Form.Control
                  type="text"
                  value={newMessage}
                  onChange={(e) => setNewMessage(e.target.value)}
                  placeholder="Type your message..."
                  className="flex-grow-1"
                  disabled={isPostOwner && !selectedUser}
                />
                <Button 
                  type="submit" 
                  variant="primary" 
                  className="d-flex align-items-center gap-2"
                  disabled={isPostOwner && !selectedUser}
                >
                  <Send size={16} />
                  Send
                </Button>
              </Form>
            </Card.Footer>
          </Card>
        )}
      </div>
    </div>
  );
};

export default ChatComponent;