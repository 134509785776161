import { useEffect, useState, useCallback } from "react";
import useAuth from "./useAuth";
import useAxiosPrivate from "./usePrivate";

export default function useTenants() {
    const { isLoggedIn } = useAuth();
    const axiosPrivateInstance = useAxiosPrivate();
    const [tenants, setTenants] = useState([]);
    const [loading, setLoading] = useState(false); 
    const [error, setError] = useState(null);

    const getTenants = useCallback(async () => {
        if (!isLoggedIn) {
            return;
        }

        setLoading(true);
        setError(null);

        try {
            const { data } = await axiosPrivateInstance.get('onboard/tenant_list/');
            setTenants(data);
        } catch (err) {
            console.error("Error fetching tenants:", err.response);
            if (err.response?.status === 404) {
                setError('Tenants not found');
            } else {
                setError(err.response?.data || "An error occurred while fetching tenants.");
            }
        } finally {
            setLoading(false);
        }
    }, [isLoggedIn, axiosPrivateInstance]); // Add dependencies here

    useEffect(() => {
        if (isLoggedIn) {
            getTenants();
        }
    }, [isLoggedIn, getTenants]);

    return { tenants, loading, error, getTenants };
}